
export class TwoWayMap {
    constructor(map = {}) {
        this.map = map;
        this.reverseMap = new Map();
        for (const key in map) {
            const value = map[key];
            this.reverseMap[value] = key;
        }
    }

    getFromKey(key) { return this.map[key]; }
    getFromValue(value) { return this.reverseMap[value]; }

    add(key, value) {
        this.map[key] = value;
        this.reverseMap[value] = key;
    }

    remove(key, value) {
        delete this.map[key];
        delete this.reverseMap[value];
    }
}

export function generateUUID() {
    let uuid = '', ii;
    const characters = '0123456789abcdef';

    for (ii = 0; ii < 36; ii += 1) {
        switch (ii) {
            case 8:
            case 13:
            case 18:
            case 23:
                uuid += '-';
                break;
            case 14:
                uuid += '4';
                break;
            case 19:
                uuid += characters[(Math.random() * 4 | 8)];
                break;
            default:
                uuid += characters[(Math.random() * 16 | 0)];
        }
    }

    return uuid;
}