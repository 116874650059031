import { useEffect, useRef, useState } from "react"
import ChatHistoryComponent, { ChatHistoryComponentInterface } from "./ChatHistoryComponent"
import InputComponent, { defaultInputHeight } from "./InputComponent"
import { sendQuestionToMetahuman, sendQuestionToExhuman, sendQuestion } from "../../app/api"
import * as amplitude from '@amplitude/analytics-browser';
import { useCookies } from "react-cookie"
import ControlsComponent from "./ControlsComponent"
import { messageSentKey } from "../../app/const-keys"
import VKComponent, { VKHeight } from "./VKComponent"
import { startSpeechRecognition, stopSpeechRecognition } from "../../app/voice-api-waiting"
import ProfileWidget from "./ProfileWidget"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentAvatarConfig, selectLastMessageToSend, setLastMessageToSend, setAvatarAnswer, selectIsExhumanMuted } from "../../app/redux/defaultSlice"
import { isBrowser } from "react-device-detect"
import { setupWaitingSpeechRecognition } from "../../app/voice-api-waiting"
import SuggestedTopicsComponent from "./SuggestedTopicsComponent";
import { customAlert } from "../../app/utils";

const suggestedTopicWidth = 477

const ChatComponent = ({ metahuman: metahuman }: {
    metahuman?: boolean
}) => {
    const [input, setInput] = useState('')
    const historyContainerRef = useRef<HTMLDivElement>(null)
    const historyComponentRef = useRef<ChatHistoryComponentInterface>(null)
    const [cookies, setCookie] = useCookies<string>([messageSentKey]);
    const dispatch = useDispatch()
    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const [inputHeight, setInputHeight] = useState(defaultInputHeight)

    const [isVKOn, setIsVKOn] = useState(true)
    const [isThinking, setIsThinking] = useState(false)

    const [isSuggestedTopicsOpened, setIsSuggestedTopicsOpened] = useState(false)

    const checkHistoryBlock = (newInputHeight: number) => {
        let defaultPadding = 300

        defaultPadding -= newInputHeight
        if (isVKOn) {
            defaultPadding -= VKHeight
        }

        historyContainerRef.current!.style.paddingBottom = `${defaultPadding}px`
        historyContainerRef.current!.style.height = historyCalcHeight(newInputHeight, isVKOn)
    }

    const oneRenderPassed = useRef(false)
    const [isVoiceOn, setIsVoiceOn] = useState(false)
    const [isUserSpeaking, setIsUserSpeaking] = useState(false)
    const isVoiceOnRef = useRef(false)
    const isBotHoldingMic = useRef(false)
    const lastMessageToSend = useSelector(selectLastMessageToSend)
    const setIsVoiceOnProxy = (val: boolean) => {
        setIsVoiceOn(val)
        isVoiceOnRef.current = val
        if (!isBotHoldingMic.current) {
            if (val) {
                startSpeechRecognition()
            } else {
                stopSpeechRecognition()
            }
        }
    }

    // sending
    useEffect(() => {
        if (lastMessageToSend) {
            doSend(lastMessageToSend)
            dispatch(setLastMessageToSend(''))
        }
    }, [lastMessageToSend])

    const addNewMessageAndScrollDown = (text: string, isUser: boolean) => {
        historyComponentRef.current!.addNewMessage(text, isUser)
    }

    const isMuted = useSelector(selectIsExhumanMuted)

    const doSend = async (question: string) => {
        if (avatarConfig == null) {
            customAlert('no avatar loaded')
            return
        }
        question = question.trim()
        if (question == '') {
            return
        }

        addNewMessageAndScrollDown(question, true)

        setInput('')

        processMessageCounting(cookies, setCookie)

        const timeoutInt = setTimeout(() => {
            setIsThinking(true)
        }, 1500)

        try {
            isBotHoldingMic.current = true
            if (isVoiceOnRef.current) {
                stopSpeechRecognition()
            }
            const onFinishedSpeaking = () => {
                isBotHoldingMic.current = false
                if (isVoiceOnRef.current) {
                    startSpeechRecognition()
                }
            }

            let answerText: string
            let videoBlob: Blob | undefined
            if (metahuman) {
                answerText = await sendQuestionToMetahuman(question, avatarConfig.name, onFinishedSpeaking)
            } else {
                if (isMuted) {
                    answerText = await sendQuestion(question, avatarConfig.name)
                } else {
                    const { text, exhumanVideoBlob } = await sendQuestionToExhuman(question, avatarConfig.name)
                    answerText = text
                    videoBlob = exhumanVideoBlob
                }
            }

            dispatch(setAvatarAnswer({
                text: answerText,
                exhumanVideoBlob: videoBlob
            }))

            addNewMessageAndScrollDown(answerText, false)

            setIsVKOn(true)

        } catch (err) {
            isBotHoldingMic.current = false
            customAlert(`${err}`)
        } finally {
            clearTimeout(timeoutInt)
            setIsThinking(false)
        }
    }

    useEffect(() => {
        checkHistoryBlock(defaultInputHeight)

        if (!oneRenderPassed.current && metahuman) {
            const onNotAvailable = () => {
                // subdue button?
            }
            const onRecognized = (text: string) => {
                dispatch(setLastMessageToSend(text))
            }
            const onError = (err: string) => {
                setIsVoiceOn(false)
                isVoiceOnRef.current = false
                customAlert(`Voice recognition error: ${err}`)
            }
            const onSpeaking = (isSpeaking: boolean) => {
                setIsUserSpeaking(isSpeaking)
            }
            setupWaitingSpeechRecognition(onNotAvailable, onRecognized, onError, onSpeaking)

            oneRenderPassed.current = true
        }
    }, [])

    let inputPlaceholder = ''
    if (avatarConfig?.display_name) {
        inputPlaceholder = `Message ${avatarConfig?.display_name}...`
    }

    return (
        <div className='h-full flex overflow-hidden'>
            <div
                style={{
                    height: '100%',
                    paddingBottom: 25,
                    transition: 'margin-right 0.5s ease',
                    marginRight: isSuggestedTopicsOpened ? '24px' : '17.5%'
                }}
                className='flex flex-col flex-grow bottom-10 top-[0px]'
            >

                <ChatHistoryComponent
                    avatarConfig={avatarConfig}
                    isThinking={isThinking}
                    historyContainerRef={historyContainerRef}
                    ref={historyComponentRef}
                />

                <div className='h-[66px]'>

                </div>

                <VKComponent
                    shown={isVKOn}
                    onSelect={(text: string) => {
                        dispatch(setLastMessageToSend(text))
                        setIsVKOn(false)
                    }} onOpenSuggestedTopics={() => {
                        setIsSuggestedTopicsOpened(!isSuggestedTopicsOpened)
                    }} />

                <div className='flex-grow' style={{ zIndex: 1 }}>
                    <InputComponent
                        placeholder={inputPlaceholder}
                        value={input}
                        onHeightChange={(newInputHeight: number) => {
                            setInputHeight(newInputHeight)
                        }}
                        onEnter={() => dispatch(setLastMessageToSend(input))}
                        onChange={(newVal: string) => setInput(newVal)}
                    />
                </div>

                {(isBrowser && metahuman) && <ControlsComponent
                    isVoiceOn={isVoiceOn}
                    setIsVoiceOn={setIsVoiceOnProxy}
                    isUserSpeaking={isUserSpeaking}
                />}

                {metahuman && <ProfileWidget metahuman />}

            </div>
            <div style={{
                transition: 'width 0.5s ease',
                width: isSuggestedTopicsOpened ? suggestedTopicWidth : 0,
            }}>
                <div style={{ width: suggestedTopicWidth }} className='flex flex-col h-full'>
                    <SuggestedTopicsComponent
                        onSelectQuestion={(question: string) => {
                            dispatch(setLastMessageToSend(question))
                        }}
                        onCloseSuggestedTopics={() => {
                            setIsSuggestedTopicsOpened(false)
                        }} />
                </div>

            </div>
        </div>
    )
}

const processMessageCounting = (cookies: { [key: string]: any }, setCookie: (name: string, value: any) => void) => {
    const messagesSentNowNumber = (cookies[messageSentKey] ?? 0) + 1
    if (messagesSentNowNumber == 1) {
        amplitude.track('First message sent')
    } else if (messagesSentNowNumber == 3) {
        amplitude.track('Became a regular user')
    }
    setCookie(messageSentKey, messagesSentNowNumber)

    amplitude.track('Message sent', { count: messagesSentNowNumber })
}

export const historyCalcHeight = (inputHeight: number, isVKOn: boolean) => {
    let bottomBlocksHeight = inputHeight
    if (isVKOn) {
        bottomBlocksHeight -= VKHeight
    }
    return `calc(100% - ${bottomBlocksHeight}px)`
}

export const chatWidth = 416 + 30

export default ChatComponent