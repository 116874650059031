declare var SpeechRecognition: any
declare var webkitSpeechRecognition: any

let recognition: any

export const setupPTTSpeechRecognition = (
    onNotAvailable: () => void,
    onRecognized: (text: string) => void,
    onError: (err: string) => void,
    onSpeaking: (isSpeaking: boolean) => void,
) => {
    if (typeof SpeechRecognition === 'function') {
        recognition = new SpeechRecognition()

    } else if (typeof webkitSpeechRecognition === 'function') {
        recognition = new webkitSpeechRecognition()
    }

    let speaking = false
    let recognizedBatch = ''
    let speakingCheckIntId: any

    const onInterimResult = (rlist: SpeechRecognitionResultList) => {
        clearTimeout(speakingCheckIntId)
        if (!speaking) {
            speaking = true
            onSpeaking(speaking)
        }

        const lastRes = rlist[rlist.length - 1]
        const transcript = lastRes[0].transcript
        
        if (lastRes.isFinal) {
            recognizedBatch += transcript
        }

        speakingCheckIntId = setTimeout(() => {
            speaking = false
            onSpeaking(speaking)
        }, 500)
    }

    if (recognition) {
        recognition.continuous = true
        recognition.interimResults = true

        recognition.onstart = function () {
            clearTimeout(speakingCheckIntId)
            speaking = true
            onSpeaking(speaking)
        };

        recognition.onresult = function (event: any) {
            const len = event.results.length
            if (len > 0) {
                onInterimResult(event.results)
            } 
        };

        recognition.onend = function (event: any) {
            onRecognized(recognizedBatch)
            recognizedBatch = ''
        }

        recognition.onerror = function (event: any) {
            console.error('Speech recognition error:', event.error);
            onError(event.error)
        };

    } else {
        onNotAvailable()
    }
}

export const startSpeechRecognition = () => {
    recognition?.start()
}

export const stopSpeechRecognition = () => {
    recognition?.stop()
}