import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { AvatarAnswer, AvatarConfig } from '../types';

export interface AvatarsState {
    avatarConfigs: AvatarConfig[] | null;
    avatarsLoadingError: string | null;
}

const initialState: AvatarsState = {
    avatarConfigs: null,
    avatarsLoadingError: null
};

export const avatarsSlice = createSlice({
    name: 'avatars',
    initialState,
    reducers: {
        setAvatarConfigs: (state, action: PayloadAction<AvatarConfig[]>) => {
            state.avatarConfigs = action.payload;
        },
        setAvatarsLoadingError: (state, action: PayloadAction<string | null>) => {
            state.avatarsLoadingError = action.payload;
        },
    },
});

export const {
    setAvatarConfigs
} = avatarsSlice.actions;

export const selectAvatarConfigs = (state: RootState) => state.avatars.avatarConfigs;

export default avatarsSlice.reducer;
