import { TwoWayMap } from "./utils";
import { webRtcPlayerObj } from "./webRtcPlayer-bridge";
let toStreamerMessages = new TwoWayMap();
let fromStreamerMessages = new TwoWayMap();

export function sendMessageToStreamer(messageType: any, indata: Array<any>) {
    console.log(`sending ${messageType}, ${indata}`);
    const messageFormat = toStreamerMessages.getFromKey(messageType);
    if (messageFormat === undefined) {
        console.error(`Attempted to send a message to the streamer with message type: ${messageType}, but the frontend hasn't been configured to send such a message. Check you've added the message type in your cpp`);
        return;
    }
    // console.log(`Calculate size: ${new Blob(JSON.stringify(indata)).size}, Specified size: ${messageFormat.byteLength}`);
    const data = new DataView(new ArrayBuffer(messageFormat.byteLength + 1));

    data.setUint8(0, messageFormat.id);
    let byteOffset = 1;

    indata.forEach((element, idx) => {
        const type = messageFormat.structure[idx];
        switch (type) {
            case "uint8":
                data.setUint8(byteOffset, element);
                byteOffset += 1;
                break;

            case "uint16":
                data.setUint16(byteOffset, element, true);
                byteOffset += 2;
                break;

            case "int16":
                data.setInt16(byteOffset, element, true);
                byteOffset += 2;
                break;

            case "double":
                data.setFloat64(byteOffset, element, true);
                byteOffset += 8;
                break;
        }
    });
    sendInputData(data.buffer);
}

function sendInputData(data: any) {
    if (webRtcPlayerObj) {
        // resetAfkWarningTimer();
        webRtcPlayerObj.send(data);
    }
}

export function populateDefaultProtocol() {
    /*
     * Control Messages. Range = 0..49.
     */
    toStreamerMessages.add("IFrameRequest", {
        "id": 0,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("RequestQualityControl", {
        "id": 1,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("FpsRequest", {
        "id": 2,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("AverageBitrateRequest", {
        "id": 3,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("StartStreaming", {
        "id": 4,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("StopStreaming", {
        "id": 5,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("LatencyTest", {
        "id": 6,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("RequestInitialSettings", {
        "id": 7,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("TestEcho", {
        "id": 8,
        "byteLength": 0,
        "structure": []
    });
    /*
     * Input Messages. Range = 50..89.
     */
    // Generic Input Messages. Range = 50..59.
    toStreamerMessages.add("UIInteraction", {
        "id": 50,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("Command", {
        "id": 51,
        "byteLength": 0,
        "structure": []
    });
    // Keyboard Input Message. Range = 60..69.
    toStreamerMessages.add("KeyDown", {
        "id": 60,
        "byteLength": 2,
        //            keyCode  isRepeat
        "structure": ["uint8", "uint8"]
    });
    toStreamerMessages.add("KeyUp", {
        "id": 61,
        "byteLength": 1,
        //            keyCode
        "structure": ["uint8"]
    });
    toStreamerMessages.add("KeyPress", {
        "id": 62,
        "byteLength": 2,
        //            charcode
        "structure": ["uint16"]
    });
    // Mouse Input Messages. Range = 70..79.
    toStreamerMessages.add("MouseEnter", {
        "id": 70,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("MouseLeave", {
        "id": 71,
        "byteLength": 0,
        "structure": []
    });
    toStreamerMessages.add("MouseDown", {
        "id": 72,
        "byteLength": 5,
        //              button     x         y
        "structure": ["uint8", "uint16", "uint16"]
    });
    toStreamerMessages.add("MouseUp", {
        "id": 73,
        "byteLength": 5,
        //              button     x         y
        "structure": ["uint8", "uint16", "uint16"]
    });
    toStreamerMessages.add("MouseMove", {
        "id": 74,
        "byteLength": 8,
        //              x           y      deltaX    deltaY
        "structure": ["uint16", "uint16", "int16", "int16"]
    });
    toStreamerMessages.add("MouseWheel", {
        "id": 75,
        "byteLength": 6,
        //              delta       x        y
        "structure": ["int16", "uint16", "uint16"]
    });
    toStreamerMessages.add("MouseDouble", {
        "id": 76,
        "byteLength": 5,
        //              button     x         y
        "structure": ["uint8", "uint16", "uint16"]
    });
    // Touch Input Messages. Range = 80..89.
    toStreamerMessages.add("TouchStart", {
        "id": 80,
        "byteLength": 8,
        //          numtouches(1)   x       y        idx     force     valid
        "structure": ["uint8", "uint16", "uint16", "uint8", "uint8", "uint8"]
    });
    toStreamerMessages.add("TouchEnd", {
        "id": 81,
        "byteLength": 8,
        //          numtouches(1)   x       y        idx     force     valid
        "structure": ["uint8", "uint16", "uint16", "uint8", "uint8", "uint8"]
    });
    toStreamerMessages.add("TouchMove", {
        "id": 82,
        "byteLength": 8,
        //          numtouches(1)   x       y       idx      force     valid
        "structure": ["uint8", "uint16", "uint16", "uint8", "uint8", "uint8"]
    });
    // Gamepad Input Messages. Range = 90..99
    toStreamerMessages.add("GamepadButtonPressed", {
        "id": 90,
        "byteLength": 3,
        //            ctrlerId   button  isRepeat
        "structure": ["uint8", "uint8", "uint8"]
    });
    toStreamerMessages.add("GamepadButtonReleased", {
        "id": 91,
        "byteLength": 3,
        //            ctrlerId   button  isRepeat(0)
        "structure": ["uint8", "uint8", "uint8"]
    });
    toStreamerMessages.add("GamepadAnalog", {
        "id": 92,
        "byteLength": 10,
        //            ctrlerId   button  analogValue
        "structure": ["uint8", "uint8", "double"]
    });

    fromStreamerMessages.add("QualityControlOwnership", 0);
    fromStreamerMessages.add("Response", 1);
    fromStreamerMessages.add("Command", 2);
    fromStreamerMessages.add("FreezeFrame", 3);
    fromStreamerMessages.add("UnfreezeFrame", 4);
    fromStreamerMessages.add("VideoEncoderAvgQP", 5);
    fromStreamerMessages.add("LatencyTest", 6);
    fromStreamerMessages.add("InitialSettings", 7);
    fromStreamerMessages.add("FileExtension", 8);
    fromStreamerMessages.add("FileMimeType", 9);
    fromStreamerMessages.add("FileContents", 10);
    fromStreamerMessages.add("TestEcho", 11);
    fromStreamerMessages.add("InputControlOwnership", 12);
    fromStreamerMessages.add("Protocol", 255);
}