import { Route, Routes, useNavigate } from "react-router-dom"
import HeaderedBlock from "./HeaderedBlock"
import { checkPromoCode, login } from "../../app/api"
import * as amplitude from '@amplitude/analytics-browser';
import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import InputWithTitleAndError from "./InputWithTitleAndError";
import { googleSvg } from "./resources";
import { customAlert, setAuth } from "../../app/utils";
import './css/WelcomeFlowBrowserComponent.css'

export default () => {
    const navigate = useNavigate()
    const [validatedPromoCode, setValidatedPromoCode] = useState<string | null>(null)

    return (
        <div style={{
            backgroundImage: 'linear-gradient(to top, rgba(242, 242, 242, 1), rgba(223, 233, 255, 1))',
            height: '100%',
        }}>
            <div style={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{
                    width: 520,
                }}>
                    <Routes>
                        <Route
                            index
                            element={
                                <HeaderedBlock title={<span>Welcome to<br />RealAvatar.AI</span>}>
                                    <SignInOrPromoSelectViewBrowserComponent validatedPromoCode={validatedPromoCode} />
                                </HeaderedBlock>
                            } />

                        <Route
                            path='promo'
                            element={
                                <div style={{ position: 'relative' }}>
                                    <HeaderedBlock title={<span>Enter promocode</span>}>
                                        <PromoViewBrowserComponent setValidatedPromoCode={setValidatedPromoCode} />
                                    </HeaderedBlock>
                                </div>
                            } />

                        <Route
                            path='promo-set'
                            element={
                                <HeaderedBlock title={<span>Welcome to<br />RealAvatar.AI</span>}>
                                    <AfterPromoView validatedPromoCode={validatedPromoCode!} />
                                </HeaderedBlock>
                            } />

                    </Routes>
                </div>
            </div>
        </div>
    )
}

const SignInOrPromoSelectViewBrowserComponent = ({ validatedPromoCode }: {
    validatedPromoCode: string | null
}) => {
    const navigate = useNavigate()
    const loginGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            login(tokenResponse.code, validatedPromoCode)
                .then((authData) => {
                    amplitude.track('Sign-In')
                    setAuth(authData)
                }).catch((err) => {
                    customAlert(err)
                })
        },
        flow: 'auth-code'
    });

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        marginBottom: 16,
    }}>
        <div className='sign-in_black-subtitle mt-[24px] mb-[120px] text-center'>
            RealAvatar individualizes the relationship<br />between creator/fan and teacher/student.
        </div>

        <div
            className='regular-button google-icon'
            style={buttonStyle}
            onClick={loginGoogle}
        >
            <i className='btn-icon'>{googleSvg}</i>Continue with Google
        </div>

        <div className='text-center' onClick={() => navigate('promo')}>
            Or <span className='sing-in__highlighted-link'>Enter a promo code</span> to join
        </div>

        {termsElement()}
    </div>
}

const PromoViewBrowserComponent = ({ setValidatedPromoCode }: {
    setValidatedPromoCode: (promoCode: string | null) => void
}) => {
    const navigate = useNavigate()
    const [promoCode, setPromoCode] = useState('')
    const [promoError, setPromoError] = useState<string>('')

    const inputMarginBottom = promoError.length > 0 ? 28 : 0

    return <div>
        <div className='sign-in__gray-subtitle mb-[120px] mt-[16px] text-center'>
            Already have an account? <span className='sign-in__gray-subtitle--underline' onClick={() => navigate('/')}>
                Sign in
            </span>
        </div>

        <div style={{
            display: 'flex',
            gap: 8,
            alignItems: 'end',
            marginBottom: inputMarginBottom
        }}>
            <InputWithTitleAndError
                className='focus:outline-none regular-input'
                autoFocus
                title='Promo code'
                errorMessage={promoError}
                placeholder='Enter promo code'
                value={promoCode}
                onChange={(event: any) => {
                    setPromoCode(event.target.value)
                    setPromoError('')
                }}
            />

            <div
                className='deep-purple-button'
                style={{
                    backgroundColor: promoCode.length > 0 ? undefined : 'rgba(192, 187, 240, 1)',
                    cursor: promoCode.length > 0 ? undefined : 'default',
                    minWidth: 125,
                }}
                onClick={() => {
                    if (promoCode.length > 0) {
                        checkPromoCode(promoCode)
                            .then(() => {
                                setPromoError('')
                                setValidatedPromoCode(promoCode)
                                navigate('../promo-set')
                            })
                            .catch((err: any) => {
                                setValidatedPromoCode(null)
                                setPromoError(`${err}`)
                            })
                    }
                }}
            >
                Apply
            </div>
        </div>
    </div>
}

const AfterPromoView = ({ validatedPromoCode }: {
    validatedPromoCode: string
}) => {
    const navigate = useNavigate()

    const loginGoogleWithSignupEvent = useGoogleLogin({
        onSuccess: async tokenResponse => {
            login(tokenResponse.code, validatedPromoCode).then((authData) => {
                navigate('../')
                amplitude.track('Sign-Up')
                setAuth(authData)
            }).catch((err) => {
                customAlert(err)
            })
        },
        flow: 'auth-code'
    });

    return <>
        <div className='sign-in_black-subtitle text-center mt-[24px]'>
            RealAvatar individualizes the relationship between creator/fan and teacher/student.
        </div>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            marginBottom: 16,
            marginTop: 120,
            height: '100%'
        }}>

            <div style={buttonStyle} onClick={loginGoogleWithSignupEvent}>
                <i className='btn-icon'>{googleSvg}</i>Continue with Google
            </div>

            {termsElement()}
        </div>
    </>
}

const termsElement = () => {
    const underlinedAndThinStyle = { fontWeight: 400, textDecoration: 'underline' }
    return <div style={{
        color: 'rgba(112, 112, 112, 1)',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500,
        padding: '0 10px 0 10px'
    }}>
        By creating an account, you agree to the <a target='_blank'href='https://www.realavatar.ai/terms-of-service' style={underlinedAndThinStyle}>Terms of Service</a> and <a href='https://www.realavatar.ai/privacy-policy' target='_blank' style={underlinedAndThinStyle}>Privacy Policy</a>
    </div>
}

const buttonStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'rgba(208, 213, 221, 1)',
    cursor: 'pointer',
    fontWeight: 600,
    color: 'rgba(52, 64, 84, 1)'
}
