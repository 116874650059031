import './css/TypingIndicator.css'

export default ({ style }: {
    style: any
}) => {

    return (
        <div>
            <div
                style={style}
                className='typing-dots'>
                <span></span>
                <span></span> 
                <span></span>
            </div>
        </div>
    )
}