const HeaderedBlock = ({ title, children }: {
    title: React.ReactNode,
    children: any
}) => {
    return (
        <div
            className='header-block'
            style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'space-between',
                justifyItems: 'center',
                borderRadius: 16,
                padding: 32
            }}>

            <div className='flex justify-center mb-[12px]'>
                <img
                    width={60}
                    height={60}
                    src='images/logo.svg'
                />
            </div>

            <div className='sign-in__header'>
                {title}
            </div>

            {children}

        </div>
    )
}

export default HeaderedBlock