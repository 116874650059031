import { error } from "console";

interface InputWithTitleAndErrorProps extends React.InputHTMLAttributes<HTMLInputElement> {
    title?: string;
    errorMessage?: string
}

const InputWithTitleAndError: React.FC<InputWithTitleAndErrorProps> = ({ title, errorMessage, ...rest }) => {
    const isError = errorMessage && errorMessage.length > 0

    return (
        <span style={{ width: '100%', position: 'relative' }}>
            <div style={{
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 5
            }}>
                {title}
            </div>
            <input {...rest} style={{ borderColor: (isError ? 'rgba(253, 162, 155, 1)' : undefined) }} />
            {isError && <>
                <div style={{ position: 'absolute', right: 14, bottom: 14 }}>
                    {alertSvg}
                </div>
                <div style={{
                    position: 'absolute',
                    fontSize: 14,
                    fontWeight: 400,
                    marginTop: 6,
                    color: 'rgba(240, 68, 56, 1)',
                }}>
                    {errorMessage}
                </div>
            </>}
        </span>
    )
}

const alertSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_844_674)">
        <path d="M7.99967 5.33325V7.99992M7.99967 10.6666H8.00634M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z" stroke="#F04438" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
        <clipPath id="clip0_844_674">
            <rect width="16" height="16" fill="white" />
        </clipPath>
    </defs>
</svg>


export default InputWithTitleAndError;