import { useCookies } from "react-cookie";
import { authDataKey } from "../../app/const-keys";
import IntroScreenComponent from "./IntroScreenComponent";
import { isBrowser } from "react-device-detect";
import WelcomeFlowBrowserComponent from "./WelcomeFlowBrowserComponent";
import WelcomeFlowMobileComponent from "./WelcomeFlowMobileComponent";

const FirstScreensComponent = ({ onStartSession }: {
    onStartSession: () => void
}) => {
    const [cookies] = useCookies<string>([authDataKey]);

    const authJson = cookies[authDataKey]
    const isAuthed = authJson != null

    return (
        isAuthed ? <IntroScreenComponent onStartSession={onStartSession}/> : <WelcomeScreenComponent/>
    )
}

export const WelcomeScreenComponent = () => {
    return isBrowser ? <WelcomeFlowBrowserComponent/> : <WelcomeFlowMobileComponent/>
 }

export default FirstScreensComponent;