import { RefObject, useEffect, useRef, useState } from 'react'
import './ExhumanComponent.css'
import { setupPTTSpeechRecognition, startSpeechRecognition, stopSpeechRecognition } from '../../app/voice-api-ptt'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentAvatarConfig, selectAvatarAnswer, setLastMessageToSend, selectIsExhumanMuted, setIsExhumanMuted, selectIsOnlyFirstIntroMessage } from '../../app/redux/defaultSlice'
import { customAlert } from '../../app/utils'
import { useCookies } from 'react-cookie'
import { firstInteractionPlayedKey } from '../../app/const-keys'
import { AvatarConfig } from '../../app/types'

export default () => {
    const videoRef = useRef<HTMLVideoElement>(null)

    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    return <>
        <div className='h-full'>
            <div className='exh__column flex flex-col'>

                <div className='flex items-center gap-[8px]'>
                    <div className='exh__avatar-name mb-[8px]'>
                        {avatarConfig?.display_name}
                    </div>
                    {avatarConfig?.is_verified && <div className='pb-[6px]'>
                        <img width={24} height={24} src='/images/verified.svg' />
                    </div>}
                </div>

                <div className='exh__profession mb-[24px]'>
                    {avatarConfig?.profession}
                </div>

                <div className='exh__bot-head relative'>
                    <img className='exh__bot-head' />
                    <video
                        ref={videoRef}
                        className='exh__bot-head absolute top-0 left-0 bottom-0 right-0'
                        autoPlay
                        loop
                        src={avatarConfig?.exhuman_idle_url} />
                </div>

                <div className='flex flex-col flex-grow'>

                    <div className='exh__bio mb-[12px]'>
                        {avatarConfig?.bio}
                    </div>

                    <div>
                        <AudioVideoToggleComponent videoRef={videoRef} />
                    </div>

                    <div className='flex-grow min-h-[20px] max-h-full'></div>

                    <div>
                        <PTTComponent />
                    </div>

                </div>

            </div>
        </div>
        <AnswerHandler videoRef={videoRef} />
        <IntroMessageChecker
            videoRef={videoRef}
            avatarConfig={avatarConfig}
        />
    </>
}

const AnswerHandler = ({ videoRef }: {
    videoRef: RefObject<HTMLVideoElement>
}) => {

    const avatarAnswer = useSelector(selectAvatarAnswer)
    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const isMuted = useSelector(selectIsExhumanMuted)
    useEffect(() => {
        if (isMuted && videoRef.current) {
            if (videoRef.current.src != avatarConfig?.exhuman_idle_url) {
                videoRef.current.src = avatarConfig?.exhuman_idle_url ?? ''
            }
        }
    }, [isMuted])

    useEffect(() => {
        if (avatarAnswer) {
            if (!avatarConfig) {
                customAlert('no avatar config, please check url for a real avatar')
                return
            }

            try {
                const videoBlob = avatarAnswer.exhumanVideoBlob
                const videoElement = videoRef.current
                if (videoBlob && videoElement && !isMuted) {
                    const videoUrl = URL.createObjectURL(videoBlob)
                    videoElement.src = videoUrl
                    videoElement.loop = false
                    videoElement.load()
                    videoElement.addEventListener('ended', () => {
                        videoElement.loop = true
                        videoElement.src = avatarConfig.exhuman_idle_url

                        videoElement.load()
                    })
                }

            } catch (err) {
                customAlert(`doing exh video: ${err}`)
            }
        }
    }, [avatarAnswer])

    return <></>
}

const IntroMessageChecker = ({ videoRef, avatarConfig }: {
    videoRef: RefObject<HTMLVideoElement>,
    avatarConfig: AvatarConfig | null
}) => {
    const [cookies, setCookie] = useCookies([firstInteractionPlayedKey])
    const isFirstInteractionPlayed = cookies[firstInteractionPlayedKey] ?? {}

    const isOnlyFirstIntroMessage = useSelector(selectIsOnlyFirstIntroMessage)
    useEffect(() => {
        const videoElement = videoRef.current
        if (!isFirstInteractionPlayed[avatarConfig?.name ?? ''] &&
            isOnlyFirstIntroMessage &&
            videoElement &&
            avatarConfig?.first_chat_message_video_url) {

            videoElement.src = avatarConfig?.first_chat_message_video_url
            videoElement.loop = false
            videoElement.load()
            videoElement.addEventListener('ended', () => {
                const isFirstInteractionPlayedUpd = { ...isFirstInteractionPlayed }
                isFirstInteractionPlayedUpd[avatarConfig.name] = true
                setCookie(firstInteractionPlayedKey, isFirstInteractionPlayedUpd)
                videoElement.loop = true
                videoElement.src = avatarConfig.exhuman_idle_url
                videoElement.load()
            })
        }
    }, [isOnlyFirstIntroMessage, avatarConfig, isFirstInteractionPlayed])

    return <></>
}

const PTTComponent = () => {
    const dispatch = useDispatch()
    const [isSpacePressed, setIsSpacePressed] = useState(false)
    const [isSpaceActive, setIsSpaceActive] = useState(false)
    const [isRecognitionOff, setIsRecognitionOff] = useState(false)

    const speechStartedRef = useRef(false)
    useEffect(() => {
        let timeoutId: any
        if (isSpacePressed) {
            timeoutId = setTimeout(() => {
                startSpeechRecognition()
                speechStartedRef.current = true
                setIsSpaceActive(true)
            }, 300)

        } else if (speechStartedRef.current) {
            stopSpeechRecognition()
            speechStartedRef.current = false
            setIsSpaceActive(false)
        }
        return () => clearTimeout(timeoutId)
    }, [isSpacePressed])

    useEffect(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((mediaStream) => {
                    let tracks = mediaStream.getTracks();
                    tracks.forEach(track => track.stop());
                })
                .catch((err) => {
                    console.error('getUserMedia error', err)
                    setIsRecognitionOff(true)
                })
        } else {
            console.error('getUserMedia not supported in this browser.');
        }

        const onNotAvailable = () => {
            setIsRecognitionOff(true)
        }
        const onRecognized = (text: string) => {
            dispatch(setLastMessageToSend(text))
        }
        const onError = (err: string) => {
            customAlert(`Voice recognition error: ${err}`)
        }
        const onSpeaking = (isSpeaking: boolean) => {
            console.log('isSpeaking', isSpeaking)
        }
        setupPTTSpeechRecognition(onNotAvailable, onRecognized, onError, onSpeaking)

        const keyDownHandler = function (event: any) {
            if (event.code == 'Space') {
                setIsSpacePressed(true)
            }
        }

        const keyUpHandler = function (event: any) {
            if (event.code == 'Space') {
                setIsSpacePressed(false)
            }
        }

        window.addEventListener('keydown', keyDownHandler);
        window.addEventListener('keyup', keyUpHandler);

        return () => {
            window.removeEventListener('keydown', keyDownHandler);
            window.removeEventListener('keyup', keyUpHandler);
        }
    }, []);

    return <div className='flex justify-center'>
        <div className='flex flex-col items-center gap-[12px]'>
            <div className='exh__ptt-title'>
                Hold space to talk
            </div>
            <div className={`exh__mic ${isSpaceActive && 'on'}`}
                style={{
                    opacity: isRecognitionOff ? 0.5 : 1,
                }}>
                {micSvg}
            </div>
        </div>
    </div>
}

const AudioVideoToggleComponent = ({ videoRef }: {
    videoRef: RefObject<HTMLVideoElement>
}) => {
    const dispatch = useDispatch()

    const isMuted = useSelector(selectIsExhumanMuted)
    const setIsMuted = (muted: boolean) => {
        dispatch(setIsExhumanMuted(muted))
    }

    useEffect(() => {
        const videoElement = videoRef.current
        if (!videoElement) {
            return
        }
        if (videoElement.muted != isMuted) {
            videoElement.muted = isMuted
        }
    }, [isMuted])

    return (
        <div className='flex justify-between items-center h-[50px]'>
            <div className='exh_audio-video'>
                Audio and video
            </div>
            <div className='cursor-pointer' onClick={() => setIsMuted(!isMuted)}>
                <img src={`/images/toggle-${isMuted ? 'off' : 'on'}.svg`} />
            </div>
        </div>
    )
}

const micSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1C11.2044 1 10.4413 1.31607 9.87868 1.87868C9.31607 2.44129 9 3.20435 9 4V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V4C15 3.20435 14.6839 2.44129 14.1213 1.87868C13.5587 1.31607 12.7956 1 12 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 19V23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 23H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

