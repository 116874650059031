import { TextareaAutosize } from './TextareaAutosize';
import { useRef } from 'react';

const InputComponent = ({ placeholder, value, onChange, onEnter, onHeightChange }: {
    placeholder: string
    value: string,
    onChange: (newValue: string) => void,
    onEnter: () => void,
    onHeightChange: (h: number) => void
}) => {
    const textAreaRef = useRef(null)

    return (
        <div
            className='w-full'
        >
            <div
                className='input-container relative'>
                <TextareaAutosize
                    className='h-min-[52px] w-full pl-[14px] pr-[40px] pt-[13px] pb-[14px] focus:outline-none'
                    ref={textAreaRef}
                    autoFocus
                    placeholder={placeholder}
                    onKeyDownCapture={(event: any) => {
                        if (event.key === 'Enter') {
                            onEnter()
                            event.preventDefault()
                        }
                    }}
                    style={{
                        borderRadius: 8,
                        borderWidth: 2,
                        borderColor: 'rgba(66, 64, 159, 1)'
                    }}
                    value={value}
                    onChange={(event: any) => {
                        // don't allow multiple spaces in a row for the sake of PTT
                        let newValue: string = event.target.value
                        if (newValue.endsWith('  ')) {
                            newValue = newValue.substring(0, newValue.length - 1)
                        }
                        onChange(newValue)
                    }}
                    onResize={(event: any) => {
                        onHeightChange((event.target as any).offsetHeight)
                    }}
                />
                <button
                    onClick={() => {
                        onEnter();
                        (textAreaRef.current as any).focus()
                    }}
                    style={{
                        position: 'absolute',
                        right: 14,
                        bottom: 21,
                        width: 24,
                        height: 24,
                        borderRadius: 4,
                        backgroundColor: value.length > 0 ? 'rgba(66, 64, 159, 1)' : 'rgba(231, 233, 237, 1)',
                    }}>
                    <div className='p-[3px]'>
                        {arrowUp(value.length ? 'white' : 'rgba(174, 180, 191, 1)')}
                    </div>
                </button>
            </div>
        </div>
    )
}

export const defaultInputHeight = 52    // check if check padding and so on

const arrowUp = (color: string) => <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 19V5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 12L12 5L19 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export default InputComponent