import { useDispatch } from "react-redux"
import { setCurrentAvatarConfigAndGetSuggestedTopics } from "../../app/redux/actions"
import { AvatarConfig } from "../../app/types"
import BaseDiscoverComponent from "../Discover/BaseDiscoverComponent"
import PopupComponent from "../Infra/PopupComponent"
import '../Discover/discover.css'

const headerHeight = 120

export default ({ close }: {
    close: () => void
}) => {
    const dispatch = useDispatch()

    return (
        <PopupComponent>
            <div style={{ height: headerHeight }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0px 24px 8px 48px',
                    paddingTop: 24
                }}>
                    <div className='discover__title-popup'>
                        Discover avatars
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => close()}>
                        {closeSvg}
                    </div>
                </div>

                <div className='discover__subtitle ml-[48px]'>
                    Select an avatar to continue
                </div>  
            </div>

            <div style={{ height: `calc(100% - ${headerHeight}px)` }}>

                <div className='h-full overflow-scroll py-[60px]'>
                    <BaseDiscoverComponent onSelect={(avatarConfig: AvatarConfig) => {
                        dispatch(setCurrentAvatarConfigAndGetSuggestedTopics(avatarConfig))
                        close()
                    }} />
                </div>
            </div>
        </PopupComponent>
    )
}

const closeSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
        <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
</svg>