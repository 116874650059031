import { useEffect, useMemo, useRef, useState } from "react"
import './css/VKComponent.css'
import { useDispatch, useSelector } from "react-redux"
import { selectAskedQuestions, selectCurrentAvatarConfig, selectCurrentSuggestedTopics, setAskedQuestions } from "../../app/redux/defaultSlice"
import { SuggestedTopics, SuggestedTopicsCategory } from "../../app/types"
import { calculateBlockWidth, shuffle as shuffleArray } from "../../app/utils"

type Option = {
    id: number
    title: string
}

const VKNumber = 3
const rowHeight = 104
const minButtonWidth = 340;
const bottomInset = 14
export const VKHeight = rowHeight + bottomInset

const VKComponent = ({ onSelect, shown, onOpenSuggestedTopics }: {
    onSelect: (text: string) => void,
    shown: boolean,
    onOpenSuggestedTopics: () => void
}) => {
    const dispatch = useDispatch()

    const suggestedTopics: SuggestedTopics | null = useSelector(selectCurrentSuggestedTopics)
    const askedQuestions = useSelector(selectAskedQuestions)
    const [vkQuestions, setVkQuestions] = useState<Option[]>([])

    const allShuffledQuestionsFromST = useMemo(() => {
        const allQuestions: string[] = []
        suggestedTopics?.forEach((topic: SuggestedTopicsCategory) => {
            topic.questions.forEach((q: string) => {
                allQuestions.push(q)
            })
        })
        shuffleArray(allQuestions)
        return allQuestions
    }, [suggestedTopics])

    useEffect(() => {
        const filteredShuffledQuestionsFromST: string[] = []

        const askedQuestionsSet = new Set(askedQuestions)

        allShuffledQuestionsFromST.forEach((question: string) => {
            if (!askedQuestionsSet.has(question)) {
                filteredShuffledQuestionsFromST.push(question)
            }
        })

        let vkQuestions = filteredShuffledQuestionsFromST.map((question, i) => ({
            title: question,
            id: i
        }))

        vkQuestions = vkQuestions.slice(0, VKNumber)

        setVkQuestions(vkQuestions)
    }, [allShuffledQuestionsFromST, askedQuestions])

    return (
        <div style={{ position: 'relative' }}>
            <div
                className={`vk ${(shown && vkQuestions.length > 0) ? 'show' : 'hide'}`}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%'
                }}>

                <div className='vk__uber-block'>
                    <div className='vk__try-asking'>
                        <span>{arrowUpSvg}</span>
                        <span className='text-nowrap'>Try asking</span>
                    </div>

                    <div className='vk__suggested-topics' onClick={onOpenSuggestedTopics}>
                        <span className='text-nowrap'>Suggested topics</span>
                        <span>{arrowRightSvg}</span>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: 8,
                        overflowX: 'scroll',
                        width: '100%',
                        marginBottom: bottomInset,
                        scrollbarWidth: 'none',
                        // maskImage: 'linear-gradient(to right, #000 70%, transparent)',
                        height: rowHeight,
                        background: '#FBFBFB',
                    }}>
                    {vkQuestions.map((option) => <OptionComponent
                        id={option.id}
                        key={option.id}
                        title={option.title}
                        onSelect={(id: number) => {
                            const title = vkQuestions.find((option) => option.id == id)?.title ?? ''
                            onSelect(title!)

                            setTimeout(() => {
                                dispatch(setAskedQuestions([...askedQuestions, title]))
                            }, 1000)
                        }}
                    />)}
                </div>
            </div>
        </div>
    )
}

const OptionComponent = ({ id, title, onSelect }: {
    id: number,
    title: string,
    onSelect: (id: number) => void
}) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const w = calculateBlockWidth(title, 'vk-button', rowHeight - 30, minButtonWidth) + 100
        console.log('w', w)
        if (ref.current) {
            ref.current.style.flexBasis = `${w}px`
        } 
    }, [title, ref])

    return <div
        ref={ref}
        onClick={() => onSelect(id)}
        className='vk-button'
    >
        {title}
    </div>
}

export default VKComponent

const arrowUpSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
        <path d="M12 19V5" stroke="#6B6F79" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 12L12 5L19 12" stroke="#6B6F79" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
</svg>

const arrowRightSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
        <path d="M9 18L15 12L9 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
</svg>
