import { useEffect, useState } from "react"

const ControlsComponent = ({ isVoiceOn, setIsVoiceOn, isUserSpeaking }: {
    isVoiceOn: boolean,
    setIsVoiceOn: (on: boolean) => void,
    isUserSpeaking: boolean
}) => {
    const [isPhoneOff, setIsPhoneOff] = useState(false)

    useEffect(() => {
        const tId = setTimeout(() => {
            const audioElement = document.getElementsByTagName('audio')
            if (!audioElement || (audioElement && audioElement.length == 0)) {
                return
            }
            const isMuted = audioElement[0].muted
            setIsPhoneOff(isMuted)
        }, 2000)

        return () => clearTimeout(tId)
    }, [])

    return (

        <div style={{
            marginLeft: 40,
            left: 0,
            bottom: 32,
            position: 'fixed',
        }}>
            {isPhoneOff}
            <div style={{
                display: 'flex',
                justifyContent: 'left',
                gap: 12
            }}>
                <div
                    className={isPhoneOff ? 'control-red-bg' : 'control-bg'}
                    onClick={() => {
                        document.getElementsByTagName('audio')[0].muted = !isPhoneOff
                        setIsPhoneOff(!isPhoneOff)
                    }}>
                    {isPhoneOff ? phoneOffSvg : phoneSvg}
                </div>
                <div
                    className={isVoiceOn ? `control-bg ${isUserSpeaking && 'green' }` : 'control-red-bg'}
                    onClick={() => {
                        setIsVoiceOn(!isVoiceOn)
                    }}
                >
                    {isVoiceOn ? micSvg : micOffSvg}
                </div>
            </div>
        </div>
    )
}

const phoneSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.07 4.92999C20.9447 6.80527 21.9979 9.34835 21.9979 12C21.9979 14.6516 20.9447 17.1947 19.07 19.07M15.54 8.45999C16.4774 9.39763 17.004 10.6692 17.004 11.995C17.004 13.3208 16.4774 14.5924 15.54 15.53" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const phoneOffSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23 9L17 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 9L23 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>


const micOffSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_763_979)">
        <path d="M1 1L23 23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 9V12C9.00052 12.593 9.17675 13.1725 9.50643 13.6653C9.83611 14.1582 10.3045 14.5423 10.8523 14.7691C11.4002 14.996 12.0029 15.0554 12.5845 14.9399C13.1661 14.8243 13.7005 14.539 14.12 14.12M15 9.34V4C15.0007 3.25603 14.725 2.53832 14.2264 1.9862C13.7277 1.43408 13.0417 1.08694 12.3015 1.01217C11.5613 0.937406 10.8197 1.14034 10.2207 1.58159C9.62172 2.02283 9.20805 2.67091 9.06 3.4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.9999 16.95C16.0237 17.9464 14.7721 18.6285 13.4056 18.9086C12.039 19.1887 10.62 19.0542 9.33044 18.5223C8.0409 17.9903 6.9397 17.0853 6.16811 15.9232C5.39652 14.761 4.98974 13.3949 4.99994 12V10M18.9999 10V12C18.9996 12.4124 18.9628 12.824 18.8899 13.23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 19V23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 23H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
        <clipPath id="clip0_763_979">
            <rect width="24" height="24" fill="white" />
        </clipPath>
    </defs>
</svg>


const micSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1C11.2044 1 10.4413 1.31607 9.87868 1.87868C9.31607 2.44129 9 3.20435 9 4V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V4C15 3.20435 14.6839 2.44129 14.1213 1.87868C13.5587 1.31607 12.7956 1 12 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 19V23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 23H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>


const messageSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 14.5C19.5 14.942 19.3244 15.366 19.0118 15.6785C18.6993 15.9911 18.2754 16.1667 17.8333 16.1667H11.25L7.91667 19.5L6.16667 16.1667H4.5V6.16667C4.5 5.72464 4.67559 5.30072 4.98816 4.98816C5.30072 4.67559 5.72464 4.5 6.16667 4.5H17.8333C18.2754 4.5 18.6993 4.67559 19.0118 4.98816C19.3244 5.30072 19.5 5.72464 19.5 6.16667V14.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const messageOnSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 14.5C19.5 14.942 19.3244 15.366 19.0118 15.6785C18.6993 15.9911 18.2754 16.1667 17.8333 16.1667H11.25L7.91667 19.5L6.16667 16.1667H4.5V6.16667C4.5 5.72464 4.67559 5.30072 4.98816 4.98816C5.30072 4.67559 5.72464 4.5 6.16667 4.5H17.8333C18.2754 4.5 18.6993 4.67559 19.0118 4.98816C19.3244 5.30072 19.5 5.72464 19.5 6.16667V14.5Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>


export default ControlsComponent;
