declare var SpeechRecognition: any
declare var webkitSpeechRecognition: any

let recognition: any

export let voiceTimeout = 1500

export const setvoiceTimeout = (newVal: number) => {
    voiceTimeout = newVal
}

export const setupWaitingSpeechRecognition = (
    onNotAvailable: () => void,
    onRecognized: (text: string) => void,
    onError: (err: string) => void,
    onSpeaking: (isSpeaking: boolean) => void,
) => {
    if (typeof SpeechRecognition === 'function') {
        recognition = new SpeechRecognition()

    } else if (typeof webkitSpeechRecognition === 'function') {
        recognition = new webkitSpeechRecognition()
    }

    let speaking = false
    let timeoutCheckId: any | null = null
    let recognizedBatch = ''

    const onInterimResult = (rlist: SpeechRecognitionResultList) => {
        if (!speaking) {
            speaking = true
            onSpeaking(speaking)
        }

        const lastRes = rlist[rlist.length - 1]
        const transcript = lastRes[0].transcript
        
        if (lastRes.isFinal) {
            recognizedBatch += transcript
            console.log(`current final batch: ${transcript}`)
        }

        clearTimeout(timeoutCheckId)
        timeoutCheckId = setTimeout(() => {
            speaking = false
            onSpeaking(speaking)
            onRecognized(recognizedBatch)
            recognizedBatch = ''
            console.log('voiceTimeout', voiceTimeout)
        }, voiceTimeout)
    }

    if (recognition) {
        recognition.continuous = true
        recognition.interimResults = true

        recognition.onstart = function () {
            console.log('Speech recognition started');
        };

        recognition.onresult = function (event: any) {
            const len = event.results.length
            if (len > 0) {
                onInterimResult(event.results)
            } 
        };

        recognition.onerror = function (event: any) {
            console.error('Speech recognition error:', event.error);
            onError(event.error)
        };

    } else {
        onNotAvailable()
    }
}

export const startSpeechRecognition = () => {
    recognition?.start()
}

export const stopSpeechRecognition = () => {
    recognition?.stop()
}