import React from 'react';
import './App.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { amplitudeKey, googleOAuthClientId } from './app/config';
import * as amplitude from '@amplitude/analytics-browser';
import AppComponent from './components/AppComponent';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV != 'development') {
	amplitude.init(amplitudeKey)
	console.log('amplitude initialized')
}

const App = () => {
	return (
		<React.StrictMode>
			<GoogleOAuthProvider clientId={googleOAuthClientId}>
				<CookiesProvider defaultSetOptions={{ path: '/' }}>
					<BrowserRouter>
						<AppComponent />
					</BrowserRouter>
				</CookiesProvider>
			</GoogleOAuthProvider>
		</React.StrictMode>
	);
}

export default App;

