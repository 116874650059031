import { useGoogleLogin } from "@react-oauth/google"
import { Route, Routes, useNavigate } from "react-router-dom"
import { checkPromoCode, login } from "../../app/api"
import * as amplitude from '@amplitude/analytics-browser';
import { useEffect, useState } from "react";
import { googleSvg } from "./resources";
import './css/PromoViewMobileComponent.css'
import './css/SignInOrPromoSelectViewMobile.css'
import './css/AfterPromoViewComponent.css'
import { customAlert, setAuth } from "../../app/utils";

const applyButtonToBottomInset = 24

export default () => {
    const navigate = useNavigate()
    const [validatedPromoCode, setValidatedPromoCode] = useState<string | null>(null)

    return <div style={{
        height: '100%',
        background: 'black'
    }}>
        <Routes>
            <Route
                index
                element={
                    <SignInOrPromoSelectViewComponent validatedPromoCode={validatedPromoCode} />
                } />

            <Route
                path="promo"
                element={
                    <PromoViewComponent setValidatedPromoCode={setValidatedPromoCode} />
                } />

            <Route
                path="promo-set"
                element={
                    <AfterPromoViewComponent validatedPromoCode={validatedPromoCode!} />
                } />

        </Routes>
    </div>
}

const SignInOrPromoSelectViewComponent = ({ validatedPromoCode }: {
    validatedPromoCode: string | null
}) => {
    const navigate = useNavigate()
    const loginGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            login(tokenResponse.code, validatedPromoCode)
                .then((authData) => {
                    navigate('/')
                    amplitude.track('Sign-In')
                    setAuth(authData)
                }).catch((err) => {
                    customAlert(err)
                })
        },
        flow: 'auth-code'
    });

    return <div className='signin-or-promo-m-higher-content'>
        <div className='signin-or-promo-m-content'>
            <div className='signin-or-promo-m-promocode'>
                <div className='signin-or-promo-m-button'>
                    <div className='signin-or-promo-m-logo'>
                        <img src='images/logo.png' />
                    </div>
                    <div className='signin-or-promo-m-stack'>
                        <div className='signin-or-promo-m-stack-top-text'>
                            To continue, enter a promo code or sign in
                        </div>
                        <div
                            className='m-purple-button'
                            onClick={() => navigate('/promo')}>
                            Enter Promocode
                        </div>
                        <div
                            className='signin-or-promo-m-stack-signin-with-google google-icon'
                            style={buttonStyle}
                            onClick={loginGoogle}
                        >
                            <i className='btn-icon'>{googleSvg}</i>Sign in with Google
                        </div>

                        <div className='signin-or-promo-m-stack-tos'>
                            By creating an account, you agree to the Terms of Service and Privacy Policy
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const PromoViewComponent = ({ setValidatedPromoCode }: {
    setValidatedPromoCode: (promoCode: string | null) => void
}) => {
    const navigate = useNavigate()
    const [promoCode, setPromoCode] = useState('')
    const [promoError, setPromoError] = useState<string>('')

    useEffect(() => {
        setPromoError('')
    }, [promoCode])

    return <div className='w-full relative'>
        <div className='promo-m-accessories' onClick={() => {
            navigate('../')
        }}>
            <div className='promo-m-accessories-content'>
                <div className='promo-m-accessories-content-drill-in'>
                    {arrowSvg}
                </div>
                <div className='promo-m-accessories-content-detail'>
                    <div className='promo-m-accessories-content-detail-2'>
                        Back
                    </div>
                </div>
            </div>
            <div className='promo-m-logo-parent-'>
                <div className='promo-m-logo'>
                    {logoSvg}
                </div>
            </div>
        </div>
        <div className='promo-m-promocode'>
            <div className='promo-m-button'>
                <div className='promo-m-to-continue'>
                    To continue, enter a promo code
                </div>
                <div className='promo-m-promocode-2'>
                    <div className='promo-m-input-field'>
                        <div className='promo-m_input-field'>
                            <div className='promo-m_input-field-with-label'>
                                <div className='promo-m-label'>
                                    Promo code
                                </div>
                                <input className='promo-m-input'
                                    value={promoCode}
                                    onChange={(e) => {
                                        setPromoCode(e.target.value)
                                    }} />
                            </div>
                            {promoError && <div className='promo-m_input-invalid-promocode'>
                                {promoError}
                            </div>}
                        </div>
                    </div>
                    <div
                        className='m-purple-button'
                        onClick={() => {
                            if (promoCode.length > 0) {
                                checkPromoCode(promoCode)
                                    .then(() => {
                                        setPromoError('')
                                        setValidatedPromoCode(promoCode)
                                        navigate('../promo-set')
                                    })
                                    .catch((err: any) => {
                                        setValidatedPromoCode(null)
                                        setPromoError(`${err}`)
                                    })
                            } else {
                                setPromoError('Please enter promo code')
                            }
                        }}>
                        Apply
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const AfterPromoViewComponent = ({ validatedPromoCode }: {
    validatedPromoCode: string
}) => {
    const navigate = useNavigate()

    const loginGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            login(tokenResponse.code, validatedPromoCode)
                .then((authData) => {
                    navigate('/')
                    amplitude.track('Sign-Up')
                    setAuth(authData)
                }).catch((err) => {
                    customAlert(err)
                })
        },
        flow: 'auth-code'
    });
    const loginGoogleWithSignupEvent = useGoogleLogin({
        onSuccess: async tokenResponse => {
            login(tokenResponse.code, validatedPromoCode)
                .then((authData) => {
                    navigate('../')
                    amplitude.track('Sign-Up')
                    setAuth(authData)
                }).catch((err) => {
                    customAlert(err)
                })
        },
        flow: 'auth-code'
    });

    return <div className='h-full flex flex-col'>
        <div className='promo-m-accessories' onClick={() => {
            navigate('../promo')
        }}>
            <div className='promo-m-accessories-content'>
                <div className='promo-m-accessories-content-drill-in'>
                    {arrowSvg}
                </div>
                <div className='promo-m-accessories-content-detail'>
                    <div className='promo-m-accessories-content-detail-2'>
                        Back
                    </div>
                </div>
            </div>
            <div className='promo-m-logo-parent-'>
                <div className='promo-m-logo'>
                    {logoSvg}
                </div>
            </div>
        </div>
        <div className='afterpromo-m-welcome-own'>
            <div className='afterpromo-m-welcome-text'>
                Welcome to RealAvatar.AI
            </div>
            <div className='afterpromo-m-welcome-subtitle'>
                RealAvatar individualizes the relationship between creator/fan and teacher/student.
            </div>
        </div>

        <div className='afterpromo-m-bottom-stack'>
            <div className='signin-or-promo-m-stack'>
                <div
                    className='signin-or-promo-m-stack-signin-with-google google-icon'
                    style={buttonStyle}
                    onClick={loginGoogleWithSignupEvent}
                >
                    <i className='btn-icon'>{googleSvg}</i>Sign up with Google
                </div>

                <div className='afterpromo-m-sign-in' onClick={loginGoogle}>
                    Already have an account? <span className='text-underlined'>Sign in</span>
                </div>

                <div className='signin-or-promo-m-stack-tos'>
                    By creating an account, you agree to the Terms of Service and Privacy Policy
                </div>
            </div>
        </div>
    </div>
}

const arrowSvg = <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.150391 6.00684C0.150391 6.15072 0.17806 6.28353 0.233398 6.40527C0.288737 6.52702 0.374512 6.646 0.490723 6.76221L5.46289 11.6846C5.64551 11.8672 5.86686 11.9585 6.12695 11.9585C6.39811 11.9585 6.62777 11.8672 6.81592 11.6846C7.00407 11.4964 7.09814 11.2695 7.09814 11.0039C7.09814 10.7383 6.99577 10.5031 6.79102 10.2983L2.43311 6.00684L6.79102 1.71533C6.99577 1.51058 7.09814 1.27539 7.09814 1.00977C7.09814 0.744141 7.00407 0.52002 6.81592 0.337402C6.62777 0.149251 6.39811 0.0551758 6.12695 0.0551758C5.86686 0.0551758 5.64551 0.146484 5.46289 0.329102L0.490723 5.25146C0.263835 5.46729 0.150391 5.71908 0.150391 6.00684Z" fill="white" fillOpacity="0.3" />
</svg>

const logoSvg = <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path d="M31.6896 19.5711L14.4635 12.4363L28.31 26.283C27.7319 26.9686 27.0965 27.6058 26.4127 28.1875L12.5627 14.3372L19.7091 31.5914C18.8218 31.8029 17.9059 31.9418 16.9684 32L10.0793 15.3678V30.9353C9.13734 30.5697 8.23755 30.1162 7.39062 29.5874V13.2823C7.42827 12.3198 7.68369 11.4128 8.10849 10.6098C8.19094 10.452 8.28056 10.2988 8.37556 10.15C8.41589 10.0873 8.45712 10.0263 8.50013 9.96537C8.50999 9.95013 8.52075 9.9349 8.5324 9.91967C8.94645 9.33264 9.45908 8.82089 10.0461 8.40683C10.0613 8.39608 10.0766 8.38442 10.0927 8.37456C10.1536 8.33155 10.2146 8.29122 10.2773 8.25088C10.4261 8.15499 10.5793 8.06537 10.7371 7.98291C11.5876 7.5348 12.5528 7.27579 13.5772 7.26324H29.6463C30.1786 8.11018 30.6348 9.0091 31.0049 9.95193H15.4906L32.0884 16.8269C32.0329 17.7652 31.8975 18.683 31.6887 19.5711H31.6896Z" fill="white" />
    <path d="M7.38281 13.5359C7.38281 13.4508 7.3846 13.3666 7.38819 13.2823V29.5874L7.38281 29.5847V13.5359Z" fill="white" />
    <path d="M9.03561 4.5161C6.88918 5.19185 5.19086 6.88931 4.51332 9.03578C4.188 8.00063 3.62518 7.06945 2.88759 6.30856C2.09982 5.49119 1.11041 4.86832 0 4.51879C2.15001 3.84393 3.84654 2.14826 4.52318 0C4.87002 1.10326 5.48661 2.08732 6.29589 2.87421C7.05946 3.61897 7.99421 4.18718 9.03561 4.5161Z" fill="white" />
</svg>

const buttonStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'rgba(208, 213, 221, 1)',
    cursor: 'pointer',
    fontWeight: 600,
    color: 'rgba(52, 64, 84, 1)'
}