import { ReadyState } from "react-use-websocket";
import { webRtcPlayer } from "./webRtcPlayer";
import { a2fUrl } from "../config";
import { populateDefaultProtocol } from "./webRtcPlayer-bridge-messaging";
let ws
export let webRtcPlayerObj
let autoPlayAudio
let shouldShowPlayOverlay = false//true
let connect_on_load = false;
let VideoEncoderQP = "N/A";
let print_stats = true

export function connect() {
    "use strict";

    window.WebSocket = window.WebSocket || window.MozWebSocket;

    if (!window.WebSocket) {
        alert('Your browser doesn\'t support WebSocket');
        return;
    }

    console.log(`Creating a websocket connection to: ${a2fUrl}`);
    ws = new WebSocket(a2fUrl);
    ws.attemptStreamReconnection = true;

    ws.onmessagebinary = function (event) {
        if (!event || !event.data) { return; }

        event.data.text().then(function (messageString) {
            // send the new stringified event back into `onmessage`
            ws.onmessage({ data: messageString });
        }).catch(function (error) {
            console.error(`Failed to parse binary blob from websocket, reason: ${error}`);
        });
    }

    ws.onmessage = function (event) {

        // Check if websocket message is binary, if so, stringify it.
        if (event.data && event.data instanceof Blob) {
            ws.onmessagebinary(event);
            return;
        }

        let msg = JSON.parse(event.data);
        if (msg.type === 'config') {
            console.log("%c[Inbound SS (config)]", "background: lightblue; color: black", msg);
            onConfig(msg);
        } else if (msg.type === 'playerCount') {
            console.log("%c[Inbound SS (playerCount)]", "background: lightblue; color: black", msg);
        } else if (msg.type === 'offer') {
            console.log("%c[Inbound SS (offer)]", "background: lightblue; color: black", msg);
            if (!UrlParamsCheck('offerToReceive')) {
                onWebRtcOffer(msg);
            }
        } else if (msg.type === 'answer') {
            console.log("%c[Inbound SS (answer)]", "background: lightblue; color: black", msg);
            onWebRtcAnswer(msg);
        } else if (msg.type === 'iceCandidate') {
            onWebRtcIce(msg.candidate);
        } else if (msg.type === 'warning' && msg.warning) {
            console.warn(msg.warning);
        } else if (msg.type === 'peerDataChannels') {
            onWebRtcSFUPeerDatachannels(msg);
        } else {
            console.error("Invalid SS message type", msg.type);
        }
    };

    ws.onerror = function (event) {
        console.log(`WS error: ${JSON.stringify(event)}`);
    };

    ws.onclose = function (event) {

        closeStream();

        if (ws.attemptStreamReconnection === true) {
            console.log(`WS closed: ${JSON.stringify(event.code)} - ${event.reason}`);
            if (event.reason !== "") {
                showTextOverlay(`DISCONNECTED: ${event.reason.toUpperCase()}`);
            }
            else {
                showTextOverlay(`DISCONNECTED`);
            }


            let reclickToStart = setTimeout(function () {
                start(true)
            }, 4000);
        }

        ws = undefined;
    };
}

function start(isReconnection) {
    // update "quality status" to "disconnected" state
    let qualityStatus = document.getElementById("qualityStatus");
    if (qualityStatus) {
        qualityStatus.className = "grey-status";
    }


    let statsDiv = document.getElementById("stats");
    if (statsDiv) {
        statsDiv.innerHTML = 'Not connected';
    }

    if (!connect_on_load || isReconnection) {
        showConnectOverlay();
        // invalidateFreezeFrameOverlay();
        shouldShowPlayOverlay = true;
        // resizePlayerStyle();
    } else {
        connect();
    }
}

export function closeStream() {
    console.log("----------------------Closing stream----------------------")
    if (webRtcPlayerObj) {
        // Remove video element from the page.
        let playerDiv = document.getElementById('player');
        if (playerDiv?.contains(webRtcPlayerObj.video)) {
            playerDiv?.removeChild(webRtcPlayerObj.video);
        }

        webRtcPlayerObj.close();
        webRtcPlayerObj = undefined;
    }
}

// export function handleWebsocketEvent(event, ws) {
//     if (event == null || (event.data && event.data instanceof Blob)) {
//         return;
//     }

//     let msg = JSON.parse(event.data);
//     if (msg.type === 'config') {
//         console.log("%c[Inbound SS (config)]", "background: lightblue; color: black", msg);
//         onConfig(msg, ws);
//     } else if (msg.type === 'playerCount') {
//         console.log("%c[Inbound SS (playerCount)]", "background: lightblue; color: black", msg);
//     } else if (msg.type === 'offer') {
//         console.log("%c[Inbound SS (offer)]", "background: lightblue; color: black", msg);
//         if (!UrlParamsCheck('offerToReceive')) {
//             onWebRtcOffer(msg);
//         }
//     } else if (msg.type === 'answer') {
//         console.log("%c[Inbound SS (answer)]", "background: lightblue; color: black", msg);
//         onWebRtcAnswer(msg);
//     } else if (msg.type === 'iceCandidate') {
//         onWebRtcIce(msg.candidate);
//     } else if (msg.type === 'warning' && msg.warning) {
//         console.warn(msg.warning);
//     } else if (msg.type === 'peerDataChannels') {
//         onWebRtcSFUPeerDatachannels(msg);
//     } else {
//         console.error("Invalid SS message type", msg.type);
//     }
// }

function setupWebRtcPlayer(htmlElement, config) {
    webRtcPlayerObj = new webRtcPlayer(config);
    autoPlayAudio = typeof config.autoPlayAudio !== 'undefined' ? config.autoPlayAudio : true;
    htmlElement.appendChild(webRtcPlayerObj.video);
    htmlElement.appendChild(webRtcPlayerObj.audio);
    // htmlElement.appendChild(freezeFrameOverlay);

    webRtcPlayerObj.onWebRtcOffer = function (offer) {
        if (ws && ws.readyState === ReadyState.OPEN) {
            let offerStr = JSON.stringify(offer);
            console.log("%c[Outbound SS message (offer)]", "background: lightgreen; color: black", offer);
            ws.send(offerStr);
        }
    };

    webRtcPlayerObj.onWebRtcCandidate = function (candidate) {
        if (ws && ws.readyState === ReadyState.OPEN) {
            ws.send(JSON.stringify({
                type: 'iceCandidate',
                candidate: candidate
            }));
        }
    };

    webRtcPlayerObj.onWebRtcAnswer = function (answer) {
        if (ws && ws.readyState === ReadyState.OPEN) {
            let answerStr = JSON.stringify(answer);
            console.log("%c[Outbound SS message (answer)]", "background: lightgreen; color: black", answer);
            ws.send(answerStr);

            if (webRtcPlayerObj.sfu) {
                // Send data channel setup request to the SFU
                const requestMsg = { type: "dataChannelRequest" };
                console.log("%c[Outbound SS message (dataChannelRequest)]", "background: lightgreen; color: black", requestMsg);
                ws.send(JSON.stringify(requestMsg));
            }
        }
    };

    webRtcPlayerObj.onSFURecvDataChannelReady = function () {
        if (webRtcPlayerObj.sfu) {
            // Send SFU a message to let it know browser data channels are ready
            const requestMsg = { type: "peerDataChannelsReady" };
            console.log("%c[Outbound SS message (peerDataChannelsReady)]", "background: lightgreen; color: black", requestMsg);
            ws.send(JSON.stringify(requestMsg));
        }
    }

    webRtcPlayerObj.onVideoInitialised = function () {
        if (ws && ws.readyState === ReadyState.OPEN) {
            if (shouldShowPlayOverlay) {
                showPlayOverlay()
                // resizePlayerStyle();
            }
            else {
                // resizePlayerStyle();
                playStream();
            }
        }
    };

    webRtcPlayerObj.onNewVideoTrack = function (streams) {
        if (webRtcPlayerObj.video && webRtcPlayerObj.video.srcObject && webRtcPlayerObj.onVideoInitialised) {
            webRtcPlayerObj.onVideoInitialised();
        }
        updateStreamList();
    }

    // registerInputs(webRtcPlayerObj.video);

    // On a touch device we will need special ways to show the on-screen keyboard.
    // if ('ontouchstart' in document.documentElement) {
    //     createOnScreenKeyboardHelpers(htmlElement);
    // }

    if (UrlParamsCheck('offerToReceive')) {
        createWebRtcOffer();
    }

    return webRtcPlayerObj.video;
}

function UrlParamsCheck(urlParameterKey) {
    return new URLSearchParams(window.location.search).has(urlParameterKey);
}

function updateStreamList() {
    // const streamSelector = document.getElementById('stream-select');
    // for (let i = streamSelector.options.length - 1; i >= 0; i--) {
    //     streamSelector.remove(i);
    // }
    // streamSelector.value = null;
    // for (const [streamId, stream] of webRtcPlayerObj.availableVideoStreams) {
    //     var opt = document.createElement('option');
    //     opt.value = streamId;
    //     opt.innerHTML = streamId;
    //     streamSelector.appendChild(opt);
    //     if (streamSelector.value == null) {
    //         streamSelector.value = streamId;
    //     }
    // }

    // updateTrackList();
}

function onConfig(config) {
    let playerDiv = document.getElementById('player');
    let playerElement = setupWebRtcPlayer(playerDiv, config);
    // resizePlayerStyle();
    // registerMouse(playerElement);
}

function onWebRtcOffer(webRTCData) {
    webRtcPlayerObj.receiveOffer(webRTCData);
    setupStats();
}

function onWebRtcAnswer(webRTCData) {
    webRtcPlayerObj.receiveAnswer(webRTCData);
    setupStats();
}

function onWebRtcSFUPeerDatachannels(webRTCData) {
    webRtcPlayerObj.receiveSFUPeerDataChannelRequest(webRTCData);
}

function onWebRtcIce(iceCandidate) {
    if (webRtcPlayerObj) {
        webRtcPlayerObj.handleCandidateFromServer(iceCandidate);
    }
}

function createWebRtcOffer() {
    if (webRtcPlayerObj) {
        console.log('Creating offer');
        showTextOverlay('Starting connection to server, please wait');
        webRtcPlayerObj.createOffer();
    } else {
        console.log('WebRTC player not setup, cannot create offer');
        showTextOverlay('Unable to setup video');
    }
}

function playStream() {
    if (webRtcPlayerObj && webRtcPlayerObj.video) {
        if (webRtcPlayerObj.audio.srcObject && autoPlayAudio) {
            // Video and Audio are seperate tracks
            webRtcPlayerObj.audio.play().then(() => {
                // audio play has succeeded, start playing video
                playVideo();
            }).catch((onRejectedReason) => {
                console.error(onRejectedReason);
                console.log("Browser does not support autoplaying audio without interaction - to resolve this we are going to show the play button overlay.")
                showPlayOverlay();
            });
        } else {
            // Video and audio are combined in the video element
            playVideo();
        }
        // showFreezeFrameOverlay();
        // hideOverlay();
    }
}

function playVideo() {
    webRtcPlayerObj.video.play().catch((onRejectedReason) => {
        if (webRtcPlayerObj.audio.srcObject) {
            webRtcPlayerObj.audio.stop();
        }
        console.error(onRejectedReason);
        console.log("Browser does not support autoplaying video without interaction - to resolve this we are going to show the play button overlay.")
        showPlayOverlay();
    });
}

function setOverlay(htmlClass, htmlElement, onClickFunction) {
    let videoPlayOverlay = document.getElementById('videoPlayOverlay');
    if (!videoPlayOverlay) {
        let playerDiv = document.getElementById('player');
        videoPlayOverlay = document.createElement('div');
        videoPlayOverlay.id = 'videoPlayOverlay';
        playerDiv.appendChild(videoPlayOverlay);
    }

    // Remove existing html child elements so we can add the new one
    while (videoPlayOverlay.lastChild) {
        videoPlayOverlay.removeChild(videoPlayOverlay.lastChild);
    }

    if (htmlElement)
        videoPlayOverlay.appendChild(htmlElement);

    if (onClickFunction) {
        videoPlayOverlay.addEventListener('click', function onOverlayClick(event) {
            onClickFunction(event);
            videoPlayOverlay.removeEventListener('click', onOverlayClick);
        });
    }

    // Remove existing html classes so we can set the new one
    let cl = videoPlayOverlay.classList;
    for (let i = cl.length - 1; i >= 0; i--) {
        cl.remove(cl[i]);
    }

    videoPlayOverlay.classList.add(htmlClass);
}

function showTextOverlay(text) {
    let textOverlay = document.createElement('div');
    textOverlay.id = 'messageOverlay';
    textOverlay.innerHTML = text ? text : '';
    textOverlay.style = 'position: fixed';
    setOverlay('textDisplayState', textOverlay);
}

function showPlayOverlay() {
    window.showPlayOverlay()
    shouldShowPlayOverlay = false
    return

    let img = document.createElement('img');
    img.id = 'playButton';
    img.src = '/images/Play.png';
    img.style = 'background: green;'
    img.alt = 'Start Streaming';
    setOverlay('clickableState', img, event => {
        playStream();
    });
    shouldShowPlayOverlay = false;
}

function showConnectOverlay() {
    window.showConnectOverlay()
    return

    let startText = document.createElement('div');
    startText.id = 'playButton';
    startText.innerHTML = 'Click to start'.toUpperCase();

    setOverlay('clickableState', startText, event => {
        connect();
        // startAfkWarningTimer();
    });
}

function setupStats() {

    webRtcPlayerObj.aggregateStats(1 * 1000 /*Check every 1 second*/);

    let printInterval = 5 * 60 * 1000; /*Print every 5 minutes*/
    let nextPrintDuration = printInterval;

    webRtcPlayerObj.onAggregatedStats = (aggregatedStats) => {
        // const bitrateWarningElement = document.getElementById('bitrate-warning')
        // if (aggregatedStats.bitrate) {
        //     bitrateWarningElement.innerHTML = ` video bitrate: ${aggregatedStats.bitrate} kbps`
        //     if (aggregatedStats.bitrate < 30000) {
        //         bitrateWarningElement.style = 'color: red;'
        //     } else {
        //         bitrateWarningElement.style = 'color: green;'
        //     }
        // }

        return

        let numberFormat = new Intl.NumberFormat(window.navigator.language, {
            maximumFractionDigits: 0
        });
        let timeFormat = new Intl.NumberFormat(window.navigator.language, {
            maximumFractionDigits: 0,
            minimumIntegerDigits: 2
        });

        // Calculate duration of run
        let runTime = (aggregatedStats.timestamp - aggregatedStats.timestampStart) / 1000;
        let timeValues = [];
        let timeDurations = [60, 60];
        for (let timeIndex = 0; timeIndex < timeDurations.length; timeIndex++) {
            timeValues.push(runTime % timeDurations[timeIndex]);
            runTime = runTime / timeDurations[timeIndex];
        }
        timeValues.push(runTime);

        let runTimeSeconds = timeValues[0];
        let runTimeMinutes = Math.floor(timeValues[1]);
        let runTimeHours = Math.floor([timeValues[2]]);

        let receivedBytesMeasurement = 'B';
        let receivedBytes = aggregatedStats.hasOwnProperty('bytesReceived') ? aggregatedStats.bytesReceived : 0;
        let dataMeasurements = ['kB', 'MB', 'GB'];
        for (let index = 0; index < dataMeasurements.length; index++) {
            if (receivedBytes < 100 * 1000)
                break;
            receivedBytes = receivedBytes / 1000;
            receivedBytesMeasurement = dataMeasurements[index];
        }

        let qualityStatus = document.getElementById("connectionStrength");
        // "blinks" quality status element for 1 sec by making it transparent, speed = number of blinks
        let blinkQualityStatus = function (speed) {
            let iter = speed;
            let opacity = 1; // [0..1]
            let tickId = setInterval(
                function () {
                    opacity -= 0.1;
                    // map `opacity` to [-0.5..0.5] range, decrement by 0.2 per step and take `abs` to make it blink: 1 -> 0 -> 1
                    qualityStatus.style.opacity = `${Math.abs((opacity - 0.5) * 2)}`;
                    if (opacity <= 0.1) {
                        if (--iter == 0) {
                            clearInterval(tickId);
                        } else { // next blink
                            opacity = 1;
                        }
                    }
                },
                100 / speed // msecs
            );
        };

        const orangeQP = 26;
        const redQP = 35;

        let statsText = '';
        let qualityTip = document.getElementById("qualityText");
        let color;

        // Wifi strength elements
        let outer = document.getElementById("outer");
        let middle = document.getElementById("middle");
        let inner = document.getElementById("inner");
        let dot = document.getElementById("dot");

        if (VideoEncoderQP > redQP) {
            color = "red";
            blinkQualityStatus(2);
            statsText += `<div style="color: ${color}">Poor encoding quality</div>`;
            outer.style.fill = "#3c3b40";
            middle.style.fill = "#3c3b40";
            inner.style.fill = color;
            dot.style.fill = color;

        } else if (VideoEncoderQP > orangeQP) {
            color = "orange";
            blinkQualityStatus(1);
            statsText += `<div style="color: ${color}">Blocky encoding quality</div>`;
            outer.style.fill = "#3c3b40";
            middle.style.fill = color;
            inner.style.fill = color;
            dot.style.fill = color;
        } else {
            color = "lime";
            qualityStatus.style.opacity = '1';
            statsText += `<div style="color: ${color}">Clear encoding quality</div>`;
            outer.style.fill = color;
            middle.style.fill = color;
            inner.style.fill = color;
            dot.style.fill = color;
        }
        qualityTip.innerHTML = statsText;

        statsText += `<div>Duration: ${timeFormat.format(runTimeHours)}:${timeFormat.format(runTimeMinutes)}:${timeFormat.format(runTimeSeconds)}</div>`;
        // statsText += `<div>Controls stream input: ${inputController === null ? "Not sent yet" : (inputController ? "true" : "false")}</div>`;
        statsText += `<div>Audio codec: ${aggregatedStats.hasOwnProperty('audioCodec') ? aggregatedStats.audioCodec : "Not set"}</div>`;
        statsText += `<div>Video codec: ${aggregatedStats.hasOwnProperty('videoCodec') ? aggregatedStats.videoCodec : "Not set"}</div>`;
        statsText += `<div>Video Resolution: ${aggregatedStats.hasOwnProperty('frameWidth') && aggregatedStats.frameWidth && aggregatedStats.hasOwnProperty('frameHeight') && aggregatedStats.frameHeight ?
            aggregatedStats.frameWidth + 'x' + aggregatedStats.frameHeight : 'Chrome only'
            }</div>`;
        statsText += `<div>Received (${receivedBytesMeasurement}): ${numberFormat.format(receivedBytes)}</div>`;
        statsText += `<div>Frames Decoded: ${aggregatedStats.hasOwnProperty('framesDecoded') ? numberFormat.format(aggregatedStats.framesDecoded) : 'Chrome only'}</div>`;
        statsText += `<div>Packets Lost: ${aggregatedStats.hasOwnProperty('packetsLost') ? numberFormat.format(aggregatedStats.packetsLost) : 'Chrome only'}</div>`;
        statsText += `<div>Framerate: ${aggregatedStats.hasOwnProperty('framerate') ? numberFormat.format(aggregatedStats.framerate) : 'Chrome only'}</div>`;
        statsText += `<div>Frames dropped: ${aggregatedStats.hasOwnProperty('framesDropped') ? numberFormat.format(aggregatedStats.framesDropped) : 'Chrome only'}</div>`;
        statsText += `<div>Net RTT (ms): ${aggregatedStats.hasOwnProperty('currentRoundTripTime') ? numberFormat.format(aggregatedStats.currentRoundTripTime * 1000) : 'Can\'t calculate'}</div>`;
        statsText += `<div>Browser receive to composite (ms): ${aggregatedStats.hasOwnProperty('receiveToCompositeMs') ? numberFormat.format(aggregatedStats.receiveToCompositeMs) : 'Chrome only'}</div>`;
        statsText += `<div style="color: ${color}">Audio Bitrate (kbps): ${aggregatedStats.hasOwnProperty('audioBitrate') ? numberFormat.format(aggregatedStats.audioBitrate) : 'Chrome only'}</div>`;
        statsText += `<div style="color: ${color}">Video Bitrate (kbps): ${aggregatedStats.hasOwnProperty('bitrate') ? numberFormat.format(aggregatedStats.bitrate) : 'Chrome only'}</div>`;
        statsText += `<div style="color: ${color}">Video Quantization Parameter: ${VideoEncoderQP}</div>`;

        let statsDiv = document.getElementById("stats");
        statsDiv.innerHTML = statsText;

        if (print_stats) {
            if (aggregatedStats.timestampStart) {
                if ((aggregatedStats.timestamp - aggregatedStats.timestampStart) > nextPrintDuration) {
                    if (ws && ws.readyState === ReadyState.OPEN) {
                        console.log(`-> SS: stats\n${JSON.stringify(aggregatedStats)}`);
                        ws.send(JSON.stringify({
                            type: 'stats',
                            data: aggregatedStats
                        }));
                    }
                    nextPrintDuration += printInterval;
                }
            }
        }
    };

    webRtcPlayerObj.latencyTestTimings.OnAllLatencyTimingsReady = function (timings) {

        if (!timings.BrowserReceiptTimeMs) {
            return;
        }

        let latencyExcludingDecode = timings.BrowserReceiptTimeMs - timings.TestStartTimeMs;
        let encodeLatency = timings.UEEncodeMs;
        let uePixelStreamLatency = timings.UECaptureToSendMs;
        let ueTestDuration = timings.UETransmissionTimeMs - timings.UEReceiptTimeMs;
        let networkLatency = latencyExcludingDecode - ueTestDuration;

        //these ones depend on FrameDisplayDeltaTimeMs
        let endToEndLatency = null;
        let browserSideLatency = null;

        if (timings.FrameDisplayDeltaTimeMs && timings.BrowserReceiptTimeMs) {
            endToEndLatency = timings.FrameDisplayDeltaTimeMs + networkLatency + (typeof uePixelStreamLatency === "string" ? 0 : uePixelStreamLatency);
            browserSideLatency = timings.FrameDisplayDeltaTimeMs + (latencyExcludingDecode - networkLatency - ueTestDuration);
        }

        let latencyStatsInnerHTML = '';
        latencyStatsInnerHTML += `<div>Net latency RTT (ms): ${networkLatency.toFixed(2)}</div>`;
        latencyStatsInnerHTML += `<div>UE Encode (ms): ${(typeof encodeLatency === "string" ? encodeLatency : encodeLatency.toFixed(2))}</div>`;
        latencyStatsInnerHTML += `<div>UE Send to capture (ms): ${(typeof uePixelStreamLatency === "string" ? uePixelStreamLatency : uePixelStreamLatency.toFixed(2))}</div>`;
        latencyStatsInnerHTML += `<div>UE probe duration (ms): ${ueTestDuration.toFixed(2)}</div>`;
        latencyStatsInnerHTML += timings.FrameDisplayDeltaTimeMs && timings.BrowserReceiptTimeMs ? `<div>Browser composite latency (ms): ${timings.FrameDisplayDeltaTimeMs.toFixed(2)}</div>` : "";
        latencyStatsInnerHTML += browserSideLatency ? `<div>Total browser latency (ms): ${browserSideLatency.toFixed(2)}</div>` : "";
        latencyStatsInnerHTML += endToEndLatency ? `<div>Total latency (ms): ${endToEndLatency.toFixed(2)}</div>` : "";
        document.getElementById("LatencyStats").innerHTML = latencyStatsInnerHTML;
    }
}

export function loadWebrtc() {
    populateDefaultProtocol()
}